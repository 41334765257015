<template>
    <div class="box">
        <Set></Set>
    </div>
    
</template>

<script>
import set from '../../scrm_pc/worker/workerSet'  
export default {
    components: {
        'Set': set  
    },
    data() {
        return {}
    },
    mounted: function(){},
}

</script>
<style>
    .box{
        height: 100vh;
        background-color: #fff !important;
        padding: 15px;
    }
</style>
