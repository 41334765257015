<template>
    <!-- <div :class="[flag? '': 'content-box']"> -->
        <!-- <div :class="[flag? '': 'container']"> -->
        <div class="content">
            <h4 class="global_head_title">服务人员设置</h4>
            <div class="content_row flex">
                <label class="info" for="">工服补助月数</label>
                  <div class="content_right">
                    <a-input v-model="data.clothes_month" size="large" placeholder="*月"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">保洁证补助标准</label>
                  <div class="content_right">
                    <a-input  prefix="￥" v-model="data.cleaning" size="large" placeholder="元/月"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">绿化证补助标准</label>
                  <div class="content_right">
                    <a-input  prefix="￥" v-model="data.green" size="large" placeholder="元/月"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">每月标准工作天数</label>
                  <div class="content_right">
                    <a-input v-model="data.work_time" size="large" placeholder="*天"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">薪资上报最晚时间</label>
                  <div class="content_right">
                    <a-input v-model="data.salary_time" size="large" placeholder="每月*日"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">工龄补助</label>
                  <div class="content_right">
                    <div class="item_row" v-for="(item,index) in subsidy" :key="index">
                        <a-input prefix="年" v-model="item.key" size="large" />
                        <a-input prefix="￥" v-model="item.val" size="large" placeholder="*元"/>
                        <a-icon class="ico" type="close-square" @click="delSubsidy(index)"/>
                    </div>
                    <span style="cursor: pointer;"  @click="addSubsidy()"> <a-icon class="ico" type="plus-square"/> 加一条</span>
                    <!-- <a-input v-model="worker_age" size="large" placeholder="年:补贴,年:补贴 例如1:30,2:50"/> -->
                </div>
            </div>
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
            </div>
        <!-- </div> -->
        <!-- </div> -->
</template>
<script>
// import $ from "jquery";
import {isMobile,ajaxUrl,requestXml} from '../../../assets/js/request';
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            staff:'', //员工列表
            skillid:'', //技能列表
            data:{
                "clothes_month": "",
                "cleaning": "",
                "green": "",
                "work_time": "",
                "salary_time": "",
                "worker_age": "",
            },
            createtime:'', //保险到期时间
            selSkillid:'',  //技能选项
            worker_age:'', //
            subsidy:[], //工龄补助
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        this.getDetail();
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
        submit(){
            let url='/jieb/Operating/operatsetting';
            
            // let age = this.worker_age.replace(/，/g,",").replace(/：/g,":");
            // let arr=age.split(',');
            let obj={};
            let arr=this.subsidy;
            for(let i=0;i<arr.length;i++){
                let k=Number(arr[i].key) ;
                let v=Number(arr[i].val);
                obj[k]=v;
            }
            this.data.worker_age= JSON.stringify(obj);
             requestXml(url,"POST",(status,res) => {
                console.log(status,res);
                this.getDetail();
            },this.data)
        },
        delSubsidy(index){
            this.subsidy.splice(index,1);
        },
        addSubsidy(){
            let obj={key:'',val:''};
           this.subsidy.push(obj);
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Operating/operatsettshow","GET",(res) => {
                
                this.data = Object.assign({}, res);
                let age=JSON.parse(res.worker_age);
                console.log(age);
                // let str='';
                this.subsidy=[];
                for(let i in age ){
                   this.subsidy.push({'key':i,'val':age[i]});
                }
                // str = str.substr(0, str.length - 1);
                // this.worker_age =str;
            },{id: this.id})
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
   
    
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        .item_row{
        width: 400px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        }
        .item_row input{
            width: 150px!important;
            margin-right: 15px;
        }
        .item_row .ico{
            font-size: 28px;
            cursor: pointer;
            /* line-height: 50px; */
        } 
        .item_row .ant-input-affix-wrapper{
            width: auto;
        }
    }
</style>
